import Axios from 'axios';
import store from '../index';
import { logout } from '../actions/authActions';

export const axios = Axios.create();
export const CancelToken = Axios.CancelToken;
axios.defaults.headers['Content-Type'] = 'application/json';

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        const { dispatch } = store;
        if (response) {
            const text = response.request.responseText;
            if (response.status === 401 && text.indexOf('cookie') >= 0) {
                dispatch(logout());
            } else if (response.status === 500 || response.status === 400) {
                try {
                    response.data = response.data
                        .split(' ')
                        .map((a) => a.trim())
                        .map((a) => a[0].toUpperCase() + a.substring(1))
                        .join('');
                } catch (error) {
                    response.data = 'UnknownError';
                }

                throw response;
            } else {
                throw response;
            }
        }
    }
);
