import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { compose } from 'redux';
import { addLock } from '../../../../store/actions/locksActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiResetPasswordUser } from '../../../../store/utils/api/usersApi';

const ChangePasswordPage = ({ t, location }) => {
    const [error, setError] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const userID = location.pathname.split('/')[2];
        setUserId(userID);
    }, [location]);

    const {
        isSubmitting,
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setSubmitting,
    } = useFormik({
        initialValues: {
            password: '',
            passwordRepeat: '',
        },
        onSubmit: async ({ password }) => {
            try {
                await apiResetPasswordUser({ password, id: userId });
            } catch (error) {
                setError(t(`error.${error.data}`));
            }

            setSubmitting(false);
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required(t('users.enterPassword')),
            passwordRepeat: Yup.string()
                .oneOf([Yup.ref('password'), null], t('users.passwordNotMatch'))
                .required(t('users.enterPasswordRepeat')),
        }),
    });

    return (
        <>
            <Row>
                <Col xs={6}>
                    <h1>{t('users.resetPassword')}</h1>
                </Col>
                <Col xs={6}>
                    <Link
                        to={`/users/${userId}/details`}
                        className="d-block mr-0 ml-auto btn btn-primary w-fit"
                    >
                        {t('users.back')}
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center mt-5">
                <Col xs={12} lg={10} xl={8}>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicMac">
                            <Form.Label>{t('users.password')}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('users.enterPassword')}
                                {...getFieldProps('password')}
                            />
                            <Form.Text className="form-error">
                                {touched['password'] && errors['password']}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicSecret">
                            <Form.Label>{t('users.passwordRepeat')}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('users.passwordRepeat')}
                                {...getFieldProps('passwordRepeat')}
                            />

                            <Form.Text className="form-error">
                                {touched['passwordRepeat'] &&
                                    errors['passwordRepeat']}
                            </Form.Text>
                        </Form.Group>
                        <p className="form-error">{error && error}</p>
                        <Button variant="primary" type="submit">
                            {(isSubmitting && t('users.progress')) ||
                                t('users.changePassword')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

ChangePasswordPage.protoTypes = {
    addLock: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    addLock: (lock) => dispatch(addLock(lock)),
});

export default compose(
    withTranslation('translations'),
    connect(null, mapDispatchToProps)
)(ChangePasswordPage);
