import { PANEL_USERS } from '../actions/types';

const initialState = {
    users: [],
    user: {},
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case PANEL_USERS.LOAD_USERS:
            return {
                ...state,
                users: payload,
            };
        case PANEL_USERS.LOAD_USER:
            return {
                ...state,
                user: payload,
            };
        default:
            return state;
    }
}
