import { apiGetAccessHistory } from '../utils/api/accessApi';
import { ACCESS_HISTORY } from './types';

export const loadAccessHistory = (lastAccess,filter) => async (dispatch) => {
    try {
        if(lastAccess == null) {
            dispatch({ type: ACCESS_HISTORY.SET_LOADING, payload: true });
        }
        const res = await apiGetAccessHistory(lastAccess,filter);

        const data = res.data.access.map((item) => ({
            ...item,
            customName: item.grantee.customName,
            phoneNumber: item.grantee.phoneNumber,
            dateFrom: item.availability.dateFrom,
            dateTo: item.availability.dateTo,
            type: item.availability.type,
        }));
        const areMore = res.data.are_more;

        var lastAccessTime = null;
        if(areMore) {
            lastAccessTime = data.at(-1).created;
        }
        dispatch({
            type: lastAccess != null ? ACCESS_HISTORY.UPDATE_ACCESS_HISTORY: ACCESS_HISTORY.LOAD_ACCESS_HISTORY,
            payload: {
                accessHistory: data,
                lastAccess:lastAccessTime,
            },
        });
    } catch (err) {
        console.log(err);
    }
    if(lastAccess == null) {
        dispatch({ type: ACCESS_HISTORY.SET_LOADING, payload: false });
    }
};
