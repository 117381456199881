import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validateMacAdress } from '../../../../store/utils/utils';
import { compose } from 'redux';
import { addLock } from '../../../../store/actions/locksActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const NewLockPage = ({ t, addLock }) => {
    const [error, setError] = useState(null);
    const {
        isSubmitting,
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setSubmitting,
    } = useFormik({
        initialValues: {
            mac: '',
            secret: '',
        },
        onSubmit: async (values) => {
            try {
                let { mac, secret } = values;
                mac = mac.replace(/\s+/g, '');
                secret = secret.replace(/\s+/g, '');
                await addLock({ mac, secret });
            } catch (error) {
                setError(t(`error.${error}`));
            }

            setSubmitting(false);
        },
        validationSchema: Yup.object().shape({
            mac: Yup.string()
                .test('macAdress', t('locks.enterValidMac'), validateMacAdress)
                .required(t('locks.enterMac')),
            secret: Yup.string().required(t('locks.enterSecret')),
        }),
    });

    return (
        <>
            <Row>
                <Col xs={6}>
                    <h1>{t('locks.addLock')}</h1>
                </Col>
                <Col xs={6}>
                    <Link
                        to="/locks"
                        className="d-block mr-0 ml-auto btn btn-primary w-fit"
                    >
                        {t('locks.back')}
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center mt-5">
                <Col xs={12} lg={10} xl={8}>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicMac">
                            <Form.Label>{t('locks.mac')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('locks.enterMac')}
                                {...getFieldProps('mac')}
                            />
                            <Form.Text className="form-error">
                                {touched['mac'] && errors['mac']}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicSecret">
                            <Form.Label>{t('locks.secret')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('locks.enterSecret')}
                                {...getFieldProps('secret')}
                            />

                            <Form.Text className="form-error">
                                {touched['secret'] && errors['secret']}
                            </Form.Text>
                        </Form.Group>
                        <p className="form-error">{error && error}</p>
                        <Button variant="primary" type="submit">
                            {(isSubmitting && t('locks.progress')) ||
                                t('locks.addLock')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

NewLockPage.protoTypes = {
    addLock: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    addLock: (lock) => dispatch(addLock(lock)),
});

export default compose(
    withTranslation('translations'),
    connect(null, mapDispatchToProps)
)(NewLockPage);
