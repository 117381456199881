import React, { useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroll-component';
import { loadBridgeScansForLock } from "../../../../store/actions/locksActions";
import Loader from '../../../shared/loader/Loader';
import moment from 'moment';

const BridgeScansPage = ({ t, location, loadBridgeScansForLock, scans, isLoadingBridge, lastEntry, bestBridge}) => {
    const { lockId } = useParams();

	useEffect(() => {
		loadBridgeScansForLock(lockId);
	}, [loadBridgeScansForLock,lockId, location]);

    const interpretRSSI = (rssi) => {
        if (rssi === null) {
            return t("bridgeScans.noValue");
        }  else if ( rssi > -68) {
            return t("bridgeScans.rssiGood");
        }  else if (rssi <= -68 && rssi > -80) {
            return t("bridgeScans.rssiModerate");
        }else if (rssi <= -80 && rssi > -90) {
            return t("bridgeScans.rssiPoor");
        }  else {
            return t("bridgeScans.rssiUnstable");
        }
    };

	return (
		<>
			<Row>
				<Col>
					<h1>{t("bridgeScans.title",{id: lockId})}</h1>
					<h2>{t("bridgeScans.bestBridge")}</h2>
					<h3>{bestBridge}</h3>
				</Col>
			</Row>
			<Row>
				<Col>
				{ isLoadingBridge ? (
                <div className="d-flex justify-content-center mt-5">
                    <Loader size={50} />
                </div>
            ) :(
                <InfiniteScroll
                    dataLength={scans.length}
                    next={() => loadBridgeScansForLock(lockId,lastEntry)}
                    hasMore={lastEntry != null}
                    loader={<div className="d-flex justify-content-center mt-5">
                        <Loader size={20} />
                    </div>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
					<Table responsive="xl" bordered striped>
						<thead>
							<tr>
								<th>{t("bridgeScans.id")}</th>
								<th>{t("bridgeScans.created")}</th>
								<th>{t("bridgeScans.rssi")}</th>
								<th>{t("bridgeScans.rssiMeaning")}</th>
							</tr>
						</thead>
						<tbody>
							{scans.map((item) => (
								<tr key={item.created}>
									<td>{item.bridge_id}</td>
									<td>{moment.utc(item.created).local().format('YYYY-MM-DD HH:mm:ss')}</td>
									<td>{item.rssi}</td>
									<td>{interpretRSSI(item.rssi)}</td>
								</tr>
							))}
						</tbody>
					</Table>
					</InfiniteScroll>
                )}
				</Col>
			</Row>
		</>
	);
};

BridgeScansPage.protoTypes = {
	loadBridgeScansForLock: PropTypes.func.isRequired,
	scans: PropTypes.arrayOf(PropTypes.shape({})),
	isLoadingBridge: PropTypes.bool.isRequired,
    lastEntry: PropTypes.string,
    bestBridge: PropTypes.string,
};

const mapStateToProps = (state) => ({
	scans: state.locks.scans,
	lastEntry: state.locks.lastEntry,
	bestBridge: state.locks.bestBridge,
    isLoadingBridge: state.locks.isLoadingBridge,
});

const mapDispatchToProps = (dispatch) => ({
	loadBridgeScansForLock: (id,lastEntry) => dispatch(loadBridgeScansForLock(id,lastEntry)),
});

export default compose(
	withTranslation("translations"),
	connect(mapStateToProps, mapDispatchToProps)
)(BridgeScansPage);
