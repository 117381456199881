import { axios } from "../axios";

export const apiGetEntranceHistory = (lastEntrance,filter) => {
    const query = {}
    if(lastEntrance != null) {
        query['lastEntrance'] = lastEntrance
    }
    if(filter.key && filter.value) {
        query[filter.key] = filter.value
    }
    if(Object.keys(query).length > 0) {
        const queries = Object.keys(query)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))
        .join('&');
        return axios.get(`/api/get-entrance-history?${queries}`);
    } else {

        return axios.get(`/api/get-entrance-history`);
    }
};