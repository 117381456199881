import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroll-component';
import { loadLocks } from "../../../store/actions/locksActions";
import FilterInput from "../../shared/filterInput/FilterInput";
import useFilter from "../../../hooks/useFilter";
import useDebounce from '../../../hooks/useDebounce';
import { apiResetLock } from "../../../store/utils/api/lockApi";
import Loader from '../../shared/loader/Loader';

const LocksPage = ({ t, location, loadLocks, locks, offset, isLoading }) => {
	// eslint-disable-next-line
	const [filteredLocks, filter, setFilter] = useFilter(locks);
	const [show, setShow] = useState(null);

	const handleClose = () => setShow(null);
	const handleShow = (id) => setShow(id);
	const resetLock = async () => {
		await apiResetLock({ lock: show });
		await loadLocks();
		setShow(null);
	};

	const callback = () => {
        loadLocks(null,filter)
    }
    const debounce = useDebounce(callback,2)

	useEffect(() => {
		loadLocks();
	}, [loadLocks, location]);

	return (
		<>
			<Row>
				<Col xs={6}>
					<h1>{t("locks.locksPage")}</h1>
				</Col>
				<Col xs={6} className="d-flex justify-content-end">
					<Link
						to="/locks/new"
						className="d-block my-auto mr-0 btn btn-primary w-fit"
					>
						{t("locks.addLock")}
					</Link>
				</Col>
			</Row>
			<FilterInput
				filter={filter}
				setValue={(e) => {
					setFilter((prev) => ({ ...prev, value: e.target.value }))
					if(e.target.value !== '') {
                        debounce()
                    }
				}
				}
				setKey={(e) =>
					setFilter((prev) => ({ ...prev, key: e.target.value }))
				}
				filterList={[
					{ key: "id", text: t("locks.id") },
					{ key: "mac", text: t("locks.mac") },
				]}
			/>
			<Row>
				<Col>
				{ isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Loader size={50} />
                </div>
            ) :(
                <InfiniteScroll
                    dataLength={locks.length}
                    next={() => loadLocks(offset,filter)}
                    hasMore={offset != null}
                    loader={<div className="d-flex justify-content-center mt-5">
                        <Loader size={20} />
                    </div>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
					<Table responsive="xl" bordered striped>
						<thead>
							<tr>
								<th>{t("locks.id")}</th>
								<th>{t("locks.mac")}</th>
								<th>{t("locks.lockName")}</th>
								<th>{t("locks.facilityName")}</th>
								<th>{t("locks.firmwareVersion")}</th>
								<th>{t("locks.init")}</th>
								<th>{t("locks.bridge")}</th>
								<th>{t("locks.time")}</th>
								<th>{t("locks.owner")}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{locks.map((item) => (
								<tr key={item.id}>
									<td>{item.id}</td>
									<td>{item.mac}</td>
									<td>{item.name}</td>
									<td>{item.facility}</td>
									<td>{item.firmwareVersion}</td>
									<td>
										{item.init
											? t("locks.isInit")
											: t("locks.notInit")}
									</td>
									<td>
										{item.bridge
											? t("locks.connected")
											: t("locks.notConnected")}
										<br/>
										<Link
												to={`/locks/${item.id}/bridge-scans`}
											>
												{t("locks.scans")}
										</Link>
									</td>
									<td>{item.time}</td>
									<td>
										{item.owner && (
											<Link
												to={`/users/${item.owner}/details`}
											>
												{t("locks.owner")}
											</Link>
										)}
									</td>
									<td>
										<Button
											variant="danger"
											size="sm"
											onClick={() => handleShow(item.id)}
										>
											{t("locks.reset")}
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					</InfiniteScroll>
                )}
				</Col>
			</Row>
			<Modal show={show !== null} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t("locks.resetTitle")} {show}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ color: "red" }}>
					{t("locks.resetDescription")}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t("locks.close")}
					</Button>
					<Button variant="danger" onClick={resetLock}>
						{t("locks.reset")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

LocksPage.protoTypes = {
	loadLocks: PropTypes.func.isRequired,
	locks: PropTypes.arrayOf(PropTypes.shape({})),
	isLoading: PropTypes.bool.isRequired,
    offset: PropTypes.number,
};

const mapStateToProps = (state) => ({
	locks: state.locks.locks,
	offset: state.locks.offset,
    isLoading: state.locks.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
	loadLocks: (offset,filter) => dispatch(loadLocks(offset,filter)),
});

export default compose(
	withTranslation("translations"),
	connect(mapStateToProps, mapDispatchToProps)
)(LocksPage);
