import React, { useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { loadUsers } from '../../../store/actions/usersActions';
import FilterInput from '../../shared/filterInput/FilterInput';
import useFilter from '../../../hooks/useFilter';

const UsersPage = ({ t, location, loadUsers, users }) => {
    const [filteredUsers, filter, setFilter] = useFilter(users);

    useEffect(() => {
        loadUsers();
    }, [loadUsers, location]);

    return (
        <>
            <Row>
                <Col>
                    <h1>{t('users.usersPage')}</h1>
                </Col>
            </Row>
            <FilterInput
                filter={filter}
                setValue={(e) =>
                    setFilter((prev) => ({ ...prev, value: e.target.value }))
                }
                setKey={(e) =>
                    setFilter((prev) => ({ ...prev, key: e.target.value }))
                }
                filterList={[
                    { key: 'phoneNumber', text: t('users.phone') },
                    { key: 'email', text: t('users.email') },
                ]}
            />
            <Row>
                <Col>
                    <Table responsive="xl" bordered striped>
                        <thead>
                            <tr>
                                <th>{t('users.userId')}</th>
                                <th>{t('users.phone')}</th>
                                <th>{t('users.email')}</th>
                                <th>{t('users.name')}</th>
                                <th>{t('users.blocked')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.phoneNumber}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {item.firstName &&
                                            `${item.firstName} ${item.lastName}`}
                                    </td>
                                    <td>
                                        {item.blocked
                                            ? t('users.yes')
                                            : t('users.no')}
                                    </td>
                                    <td>
                                        <Link
                                            to={`/users/${item.id}/details`}
                                            className="d-block mx-auto w-fit"
                                        >
                                            {t('users.details')}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

UsersPage.protoTypes = {
    loadUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    users: state.users.users,
});

const mapDispatchToProps = (dispatch) => ({
    loadUsers: () => dispatch(loadUsers()),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(UsersPage);
