import { useState, useEffect, useRef } from 'react';

function useDebounce(callback, delay) {
    const [lastChange, setLastChange] = useState(null); 
    const callbackRef = useRef(callback);
    const isFirstRender = useRef(true); 

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (lastChange === null) return;

        const handler = setTimeout(() => {
            const now = Date.now();
            if (now - lastChange >= delay) {
                callbackRef.current();
            }
        }, delay);

        return () => clearTimeout(handler);
    }, [lastChange, delay]);

    const triggerChange = () => setLastChange(Date.now());

    return triggerChange;
}


export default useDebounce;