import React, { useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { loadAccessHistory } from '../../../store/actions/accessActions';
import FilterInput from '../../shared/filterInput/FilterInput';
import useFilter from '../../../hooks/useFilter';
import useDebounce from '../../../hooks/useDebounce';
import Loader from '../../shared/loader/Loader';

const AccessHistoryPage = ({
    t,
    location,
    loadAccessHistory,
    accessHistory,
    lastAccess,
    isLoading,
}) => {
    // eslint-disable-next-line
    const [fileteredAccessHistory, filter, setFilter] = useFilter(
        accessHistory
    );

    const callback = () => {
        loadAccessHistory(null,filter)
    }
    const debounce = useDebounce(callback,2)


    useEffect(() => {
        loadAccessHistory();
    }, [loadAccessHistory, location]);

    return (<>
            <Row>
                <Col xs={6}>
                    <h1>{t('access.accessHistoryPage')}</h1>
                </Col>
            </Row>
            <FilterInput
                filter={filter}
                setValue={(e) => {
                    setFilter((prev) => ({ ...prev, value: e.target.value }))
                    if(e.target.value !== '') {
                        debounce()
                    }
                }
                }
                setKey={(e) =>
                    setFilter((prev) => ({ ...prev, key: e.target.value }))
                }
                filterList={[
                    { key: 'lockName', text: t('access.lockName') },
                    { key: 'phoneNumber', text: t('access.granteeNumber') },
                    { key: 'lockId', text: t('entrances.lockId') },
                ]}
            />
            <Row>
                <Col >
            { isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Loader size={50} />
                </div>
            ) :(
                <InfiniteScroll
                    dataLength={accessHistory.length}
                    next={() => loadAccessHistory(lastAccess,filter)}
                    hasMore={lastAccess != null}
                    loader={<div className="d-flex justify-content-center mt-5">
                        <Loader size={20} />
                    </div>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
                        <Table responsive="xl" bordered striped>
                            <thead>
                                <tr>
                                    <th>{t('access.lockName')}</th>
                                    <th>{t('access.facilityName')}</th>
                                    <th>{t('access.accessType')}</th>
                                    <th>{t('access.accessName')}</th>
                                    <th>{t('access.granteeNumber')}</th>
                                    <th>{t('access.grantorNumber')}</th>
                                    <th>{t('access.revoked')}</th>
                                    <th>{t('access.timeLabel')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accessHistory.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.lockName}</td>
                                        <td>{item.facility}</td>
                                        <td>
                                            {item.tokenOrKey === 'key'
                                                ? t(`key.${item.type}`)
                                                : t(`token.${item.type}`)}
                                        </td>
                                        <td>{item.customName}</td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.grantor}</td>
                                        <td>
                                            {item.revoked
                                                ? t('access.yes')
                                                : t('access.no')}
                                        </td>
                                        <td>{`${item.dateFrom} - ${
                                            item.dateTo !== undefined
                                                ? item.dateTo
                                                : ''
                                        }`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                )}
                </Col>
            </Row>
        </>
    );
};

AccessHistoryPage.protoTypes = {
    loadAccessHistory: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lastAccess: PropTypes.string,
    accessHistory: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    accessHistory: state.accessHistory.accessHistory,
    lastAccess: state.accessHistory.lastAccess,
    isLoading: state.accessHistory.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    loadAccessHistory: (lastAccess,filter) => dispatch(loadAccessHistory(lastAccess,filter)),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(AccessHistoryPage);
