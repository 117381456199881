import { useEffect, useState } from 'react';

export default (array) => {
    const [filterArray, setFilterArray] = useState([]);
    const [filter, setFilter] = useState({ key: '', value: '' });

    useEffect(() => {
        if (filter.value === '' || filter.key === '') {
            setFilterArray(array);
        } else {
            let value = filter.value;
            if (value.indexOf('+') > -1) {
                value = value.replace('+', '\\+');
            }
            const regex = new RegExp(value, 'i');
            setFilterArray(
                array.filter((item) => regex.test(item[filter.key]))
            );
        }
    }, [array, setFilterArray, filter]);

    return [filterArray, filter, setFilter];
};
