import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ isAuthenticated, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated !== null) setIsLoading(false);
  }, [isAuthenticated]);

  return (
    !isLoading &&
    (isAuthenticated ? <Route {...rest} /> : <Redirect to="/login" />)
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(PrivateRoute);
