import { apiGetLocks, apiAddLock,apiGetLocksScansForBridge } from "../utils/api/lockApi";
import { LOCKS } from "./types";

export const loadLocks = (offset,filter) => async (dispatch) => {
  var areMore = false
  try {
    if(offset == null) {
      dispatch({
          type: LOCKS.SET_LOADING,
          payload: true,
      });
    }
    const res = await apiGetLocks(offset,filter ?? {});
    areMore = res.data.are_more;
    var newOffset = null
    if(areMore) {
      newOffset =  parseInt(res.data.offset) +  1;
    }

    dispatch({
      type: offset != null ? LOCKS.UPDATE_LOCKS : LOCKS.LOAD_LOCKS,
      payload: {
        locks: res.data.locks,
        offset: newOffset,
      },
    });
  } catch (err) {
    console.log(err);
  }
  if(offset == null || !areMore) {
    dispatch({
        type: LOCKS.SET_LOADING,
        payload: false,
    });
  }
};

export const addLock = (data) => async (dispatch) => {
  try {
    const lock = {
      mac: data.mac.toUpperCase(),
      secret: data.secret,
    };
    await apiAddLock(lock);
    await dispatch(loadLocks);
  } catch (err) {
    throw err.data;
  }
};

export const loadBridgeScansForLock = (id,lastEntry) => async (dispatch) => {
  var areMore = false
  try {
    if(lastEntry == null) {
      dispatch({
          type: LOCKS.SET_LOADING_SCANS,
          payload: true,
      });
    }
    const res = await apiGetLocksScansForBridge(id,lastEntry);
    areMore = res.data.are_more;
    
    var lastEntryTime = null;
    if(areMore) {
      lastEntryTime = res.data.rssi_requests.at(-1).created;
    }

    dispatch({
      type: lastEntry != null ? LOCKS.UPDATE_LOCKS_SCANS : LOCKS.LOAD_LOCKS_SCANS,
      payload: {
        scans: res.data.rssi_requests,
        bestBridge: res.data.best_bridge_id,
        lastEntry: lastEntryTime,
      },
    });
  } catch (err) {
    console.log(err);
  }
  if(lastEntry == null || !areMore) {
    dispatch({
        type: LOCKS.SET_LOADING_SCANS,
        payload: false,
    });
}
};