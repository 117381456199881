import { AUTH } from "../actions/types";

const initialState = {
  isAuthenticated: null,
  user: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case AUTH.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case AUTH.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AUTH.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: initialState.user,
      };
    default:
      return state;
  }
}
