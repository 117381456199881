import { axios } from '../axios';

export const apiGetUsers = () => axios.get('/api/get-users');

export const apiGetUser = (userId) => axios.get(`/api/user?id=${userId}`);

export const apiUnblockUser = (userId) =>
    axios.put(`/api/unblock-user`, { id: userId });

export const apiResetPasswordUser = (data) =>
    axios.put(`/api/reset-user-password`, data);

    export const apiChangeLockUserRole = (data) =>
    axios.post(`/api/change-user-lock-role`, data);
