import { ENTRANCE_HISTORY } from '../actions/types';

const initialState = {
    entranceHistory: [],
    isLoading: false,
    lastEntrance: null,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case ENTRANCE_HISTORY.LOAD_ENTRANCE_HISTORY:
            return {
                ...state,
                entranceHistory: payload.entranceHistory,
                lastEntrance: payload.lastEntrance,
            };
        case ENTRANCE_HISTORY.UPDATE_ENTRANCE_HISTORY:
            return {
                ...state,
                entranceHistory: [...state.entranceHistory,...payload.entranceHistory],
                lastEntrance: payload.lastEntrance,
            };
        case ENTRANCE_HISTORY.SET_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        default:
            return state;
    }
}
