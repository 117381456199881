import { axios } from '../axios';

export const apiGetPanelUsers = () => axios.get('/api/get-panel-users');

export const apiGetPanelUser = (userId) =>
    axios.get(`/api/panel-user?id=${userId}`);

export const apiPostPanelUser = (data) => axios.post(`/api/panel-user`, data);

export const apiPutPanelUser = (data) => axios.put(`/api/panel-user`, data);

export const apiDeletePanelUser = (userId) =>
    axios.delete(`/api/panel-user?id=${userId}`);
