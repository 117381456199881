import { apiGetEntranceHistory } from '../utils/api/entranceApi';
import { ENTRANCE_HISTORY } from './types';

export const loadEntranceHistory = (lastEntrance,filter) => async (dispatch) => {
    var areMore = false
    try {
        if(lastEntrance == null) {
            dispatch({
                type: ENTRANCE_HISTORY.SET_LOADING,
                payload: true,
            });
        }
        const res = await apiGetEntranceHistory(lastEntrance,filter ?? {});
        const data = res.data.entrance.map((item) => ({
            ...item,
            customName: item.grantee?.customName,
            phoneNumber: item.grantee?.phoneNumber,
            dateFrom: item.availability?.dateFrom,
            dateTo: item.availability?.dateTo,
            actionType: item.availability?.type,
        }));
        areMore = res.data.are_more;

        var lastEntranceTime = null;
        if(areMore) {
            lastEntranceTime = data.at(-1).entranceTime;
        }

        dispatch({
            type: lastEntrance != null ? ENTRANCE_HISTORY.UPDATE_ENTRANCE_HISTORY : ENTRANCE_HISTORY.LOAD_ENTRANCE_HISTORY,
            payload: {
                entranceHistory:data,
                lastEntrance: lastEntranceTime
            },
        });
    } catch (err) {
        console.log(err);
    }
    if(lastEntrance == null || !areMore) {
        dispatch({
            type: ENTRANCE_HISTORY.SET_LOADING,
            payload: false,
        });
    }
};
