import React from "react";
import { Col, Row } from "react-bootstrap";
import Nav from "../shared/nav/Nav";

const Panel = ({ children }) => {
  return (
    <>
      <Row>
        <Col>
          <Nav />
        </Col>
      </Row>
      <Row className="justify-content-center  px-3">
        <Col xs={12} lg={10}>
          {children}
        </Col>
      </Row>
    </>
  );
};

export default Panel;
