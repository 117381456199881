import { apiGetUsers } from "../utils/api/usersApi";
import { USERS } from "./types";

export const loadUsers = () => async (dispatch) => {
  try {
    const res = await apiGetUsers();
    dispatch({
      type: USERS.LOAD_USERS,
      payload: res.data.users,
    });
  } catch (err) {
    console.log(err);
  }
};
