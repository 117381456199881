import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Navbar, Button, Nav as Navigation } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';
import styles from './Nav.module.scss';
import { logout } from '../../../store/actions/authActions';
import { Link } from 'react-router-dom';

const Nav = ({ t, isAuthenticated, user, logout }) => {
    return (
        <Navbar className={styles.nav} variant="light" expand="lg">
            <Navbar.Brand href="/" className={styles.brand}>
                <img className={styles.img} src={logo} alt="Rentinglock logo" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                <Navigation>
                    <Link className={styles.link} to="/locks">
                        {t('nav.locks')}
                    </Link>
                    <Link className={styles.link} to="/users">
                        {t('nav.users')}
                    </Link>
                    <Link className={styles.link} to="/entrance-history">
                        {t('nav.entranceHistory')}
                    </Link>
                    <Link className={styles.link} to="/access-history">
                        {t('nav.accessHistory')}
                    </Link>
                    <Link className={styles.link} to="/panel-users">
                        {t('nav.panelUsers')}
                    </Link>
                    {isAuthenticated && (
                        <div className={styles.logoutWrapper}>
                            <Navbar.Text
                                className={`text-white-50 mr-3 ${styles.user}`}
                            >{` ${user.firstName} ${user.lastName}`}</Navbar.Text>
                            <Button
                                variant="outline-light"
                                onClick={logout}
                                className={styles.logoutButton}
                            >
                                {t('nav.logout')}
                            </Button>
                        </div>
                    )}
                </Navigation>
            </Navbar.Collapse>
        </Navbar>
    );
};

Nav.protoTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object,
};
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(Nav);
