import { apiGetPanelUsers } from '../utils/api/panelUsersApi';
import { PANEL_USERS } from './types';

export const loadPanelUsers = () => async (dispatch) => {
    try {
        const res = await apiGetPanelUsers();
        dispatch({
            type: PANEL_USERS.LOAD_USERS,
            payload: res.data.users,
        });
    } catch (err) {
        console.log(err);
    }
};
