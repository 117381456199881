import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";

import PrivateRoute from "./routing/PrivateRoute";
import { loadUser } from "../store/actions/authActions";
import Panel from "./panel/Panel";
import "../i18n";
import Routes from "./routing/Routes";
import Login from "./login/Login";

const App = ({ loadUser }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <Container fluid>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/">
            <Panel>
              <Routes />
            </Panel>
          </PrivateRoute>
        </Switch>
      </Router>
    </Container>
  );
};

App.propTypes = {
  loadUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loadUser: () => dispatch(loadUser()),
});

export default connect(null, mapDispatchToProps)(App);
