import { axios } from "../axios";

export const apiGetLocks = (offset,filter) => {
    const query = {}
    if(offset) {
        query['offset'] = offset
    }
    if(filter.key && filter.value) {
        query[filter.key] = filter.value
    }
    if(Object.keys(query).length > 0) {
        const queries = Object.keys(query)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))
        .join('&');
        return axios.get(`/api/get-locks?${queries}`);
    } else {

        return axios.get(`/api/get-locks`);
    }
};
export const apiAddLock = (body) => axios.post("/api/add-lock", body);
export const apiResetLock = (body) => axios.post("/api/reset-lock", body);
export const apiGetLocksScansForBridge = (id,lastEntry) =>  axios.get(`/api/get-bridge-scans?lock=${id}${lastEntry != null ? `&lastEntry=${lastEntry}` : ''}`);
