import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormControl, InputGroup, Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const FilterInput = ({ t, setKey, setValue, filter, filterList }) => {
  return (
    <Row>
      <Col>
        <InputGroup className="mt-3">
          <FormControl
            placeholder={t("filter.enterFilter")}
            onChange={(e) => setValue(e)}
            value={filter.value}
          />
          <Form.Control
            as="select"
            onChange={(e) => setKey(e)}
            value={filter.key}
            custom>
            <option disabled value="" hidden>
              {t("filter.chooseFilter")}
            </option>
            {filterList.map((item) => (
              <option key={item.key} value={item.key}>
                {item.text}
              </option>
            ))}
          </Form.Control>
        </InputGroup>
      </Col>
    </Row>
  );
};

FilterInput.propTypes = {
  setKey: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  filterList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

export default withTranslation("translations")(FilterInput);
