import { apiAutoLogin, apiLogin, apiLogout } from "../utils/api/authApi";

import { AUTH } from "./types";

export const loadUser = () => async (dispatch) => {
  const user = localStorage.getItem("user");
  const email = localStorage.getItem("email");
  if (user && email) {
    dispatch({
      type: AUTH.USER_LOADED,
      payload: { name: user, legal_departament_email: email },
    });
  }
  try {
    const res = await apiAutoLogin();
    let { firstName, lastName, email } = res.data;

    localStorage.setItem("user", email);
    dispatch({
      type: AUTH.USER_LOADED,
      payload: { firstName, lastName, email },
    });
  } catch (err) {
    dispatch(logout());
  }
};

export const login = (email, password, remember_me) => async (dispatch) => {
  const body = JSON.stringify({
    email,
    password,
    remember_me,
  });
  try {
    const res = await apiLogin(body);

    if (res) {
      dispatch({
        type: AUTH.LOGIN_SUCCESS,
      });
      dispatch(loadUser());
    }
  } catch (error) {
    let errorMessage;

    if (error.status === 401) errorMessage = "wrong user";
    if (error.status === 500) errorMessage = "Server error";

    throw Error(errorMessage || error.message.toLowerCase());
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("user");
  apiLogout();
  dispatch({ type: AUTH.LOGOUT });
};
