import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    apiGetUser,
    apiUnblockUser,
} from '../../../../store/utils/api/usersApi';

const UserPage = ({ t, location }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const userID = location.pathname.split('/')[2];
        if (userID !== null) {
            apiGetUser(userID)
                .then((resp) => {
                    if (resp?.data) {
                        setUser(() => resp.data.user);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [location, setUser]);

    const unlockUser = async () => {
        await apiUnblockUser(user.id);
        if (user.id !== null) {
            apiGetUser(user.id)
                .then((resp) => {
                    if (resp?.data) {
                        setUser(() => resp.data.user);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <>
            <Row>
                <Col xs={6}>
                    <h1>{`${t('users.userPage')} ${
                        user !== null && user.phoneNumber
                    }`}</h1>
                </Col>
                <Col xs={6}>
                    <Link
                        to="/users"
                        className="d-block mr-0 ml-auto btn btn-primary w-fit"
                    >
                        {t('users.back')}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={10} className="d-flex mt-3 align-items-center">
                    {user?.blocked && (
                        <>
                            <h4 className="mb-0">{t('users.isBlocked')}</h4>
                            <Button
                                onClick={unlockUser}
                                className="ml-5 text-white"
                                variant="warning"
                            >
                                {t('users.unBlock')}
                            </Button>
                        </>
                    )}
                </Col>
                <Col xs={2} className="mt-3 d-flex justify-content-end">
                    <Link
                        className="d-block mr-0 ml-auto btn btn-success w-fit"
                        to={`/users/${user?.id}/reset-password`}
                    >
                        {t('users.resetPassword')}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive="xl" bordered striped>
                        <thead>
                            <tr>
                                <th>{t('users.id')}</th>
                                <th>{t('users.role')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user !== null &&
                                Object.keys(user.locks).map((key, index) => {
                                    const item = user.locks[key];
                                    return (
                                        <tr key={key}>
                                            <td>{item.lock}</td>
                                            <td>
                                                {item.role}
                                                <Link
                                                    className="ml-2 btn btn-primary"
                                                    to={`/users/${user?.id}/${item.lock}/change-role`}
                                                >
                                                    {t('users.changeRole')}
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

export default withTranslation()(UserPage);
