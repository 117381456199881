import React from "react";
import PropTypes from "prop-types";
import classes from "./Loader.module.scss";

const Loader = ({ size = 13 }) => {
	let width = size;
	let height = size;

	let firstPosition = 6;
	let secoundPosition = 26;
	let thirdPosition = 45;

	if (width !== 13) {
		secoundPosition = firstPosition + width + 7;
		thirdPosition = secoundPosition + width + 7;
	}
	let gridSize = thirdPosition + width + 5;
	return (
		<div style={{ width: gridSize, height: gridSize }}>
			<div
				className={classes.ldsGrid}
				style={{ width: gridSize, height: gridSize }}>
				<div
					style={{
						width,
						height,
						top: firstPosition,
						left: firstPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: firstPosition,
						left: secoundPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: firstPosition,
						left: thirdPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: secoundPosition,
						left: firstPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: secoundPosition,
						left: secoundPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: secoundPosition,
						left: thirdPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: thirdPosition,
						left: firstPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: thirdPosition,
						left: secoundPosition
					}}></div>
				<div
					style={{
						width,
						height,
						top: thirdPosition,
						left: thirdPosition
					}}></div>
			</div>
		</div>
	);
};

Loader.propTypes = {
	size: PropTypes.number
};

export default Loader;
