import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import auth from './reducers/authReducer';
import locks from './reducers/locksReducer';
import users from './reducers/usersReducer';
import panelUsers from './reducers/panelUsersReducer';
import entranceHistory from './reducers/entranceReducer';
import accessHistory from './reducers/accessReducer';

const middleware = [thunk];
const reducers = {
    auth,
    locks,
    users,
    panelUsers,
    entranceHistory,
    accessHistory,
};

const rootReducer = combineReducers(reducers);

let store;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const composeEnhancers = composeWithDevTools({
        trace: true, 
        traceLimit: 25, 
      });
    store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleware))
    );
} else {
    store = createStore(rootReducer, applyMiddleware(...middleware));
}

export default store;
