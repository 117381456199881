export const AUTH = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    USER_LOADED: 'USER_LOADED',
    CLEAR_DATA: 'CLEAR_DATA',
};

export const LOCKS = {
    LOAD_LOCKS: 'LOAD_LOCKS',
    UPDATE_LOCKS: 'UPDATE_LOCKS',
    SET_LOADING: 'SET_LOADING',
    SET_LOADING_SCANS: 'SET_LOADING_SCANS',
    LOAD_LOCKS_SCANS: 'LOAD_LOCKS_SCANS',
    UPDATE_LOCKS_SCANS: 'UPDATE_LOCKS_SCANS',
};

export const USERS = {
    LOAD_USERS: 'LOAD_USERS',
};

export const PANEL_USERS = {
    LOAD_USERS: 'LOAD_PANEL_USERS',
    LOAD_USER: 'LOAD_PANEL_USER',
};

export const ENTRANCE_HISTORY = {
    LOAD_ENTRANCE_HISTORY: 'LOAD_ENTRANCE_HISTORY',
    SET_LOADING: 'SET_LOADING',
    UPDATE_ENTRANCE_HISTORY: 'UPDATE_ENTRANCE_HISTORY',
};

export const ACCESS_HISTORY = {
    LOAD_ACCESS_HISTORY: 'LOAD_ACCESS_HISTORY',
    UPDATE_ACCESS_HISTORY: 'UPDATE_ACCESS_HISTORY',
    SET_LOADING: 'SET_LOADING',
};
