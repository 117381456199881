import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { loadUsers } from '../../../../store/actions/usersActions';
import { loadLocks } from '../../../../store/actions/locksActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiChangeLockUserRole } from '../../../../store/utils/api/usersApi';
import {
    apiGetUser,
} from '../../../../store/utils/api/usersApi';
const ChangeRolePage = ({ t, location, locks,users,loadLocks,loadUsers }) => {
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [role, setRole] = useState('');
    const [lock, setLock] = useState(null);

    useEffect(() => {
        const userID = location.pathname.split('/')[2];
        const lockID = location.pathname.split('/')[3];
        if (userID !== null) {
                const userID = location.pathname.split('/')[2];
                if (userID !== null) {
                    apiGetUser(userID)
                        .then((resp) => {
                            if (resp?.data) {
                                setUser(() => resp.data.user);
                                const lock = resp.data.user.locks.find((el) => el.lock === lockID);
                                setLock(() => lock);
                                setRole(() => lock.role);
                            }
                        })
                        .catch((err) => console.log(err));
                }
        }
    }, [location]);



    const onSubmit = async ()  => {
        try {
            await apiChangeLockUserRole({ lock: lock.lock, id: user.id,role:role });
            window.open(`/users/${user?.id}/details`); 
        } catch (error) {
            setError(t(`error.${error.data}`));
        }
    };
       

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Link
                        to={`/users/${user?.id}/details`}
                        className="d-block mr-0 ml-auto btn btn-primary w-fit"
                    >
                        {t('users.back')}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t('users.changeRole')} of {user?.phoneNumber} in lock {lock?.lock}</h3>
                </Col>
                
            </Row>
            <Row className='justify-content-center mt-4'>
                <Col xs={6} className="mt-4">
                <Form.Control
                    as="select"
                    value={role}
                    onChange={e => {
                        setRole(e.target.value);
                    }}
                    >
                    <option value="superAdmin">{t('users.superAdmin')}</option>
                    <option value="admin">{t('users.admin')}</option>
                    <option value="host">{t('users.host')}</option>
                    <option value="guest">{t('users.guest')}</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row className="justify-content-center mt-3">
                <Col xs={6} className="d-flex justify-content-center">
                    <p className="form-error">{error && error}</p>
                    <Button onClick={onSubmit}
                        variant="success"
                        >
                        {t('users.changeRole')}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

ChangeRolePage.protoTypes = {
    loadLocks: PropTypes.func.isRequired,
	locks: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    users: state.users.users,
	locks: state.locks.locks,
});

const mapDispatchToProps = (dispatch) => ({
    loadUsers: () => dispatch(loadUsers()),
    loadLocks: () => dispatch(loadLocks()),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(ChangeRolePage);
