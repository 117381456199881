import React, { useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { loadEntranceHistory } from '../../../store/actions/entranceActions';
import FilterInput from '../../shared/filterInput/FilterInput';
import useFilter from '../../../hooks/useFilter';
import useDebounce from '../../../hooks/useDebounce';
import Loader from '../../shared/loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';

const EntranceHistoryPage = ({
    t,
    location,
    loadEntranceHistory,
    entranceHistory,
    lastEntrance,
    isLoading,
}) => {
    // eslint-disable-next-line
    const [fileteredEntranceHistory, filter, setFilter] = useFilter(
        entranceHistory
    );

    const knownAccess = [
        'CODE_BLE_ACCESS_GRANTED',
        'OPEN_BLE_ACCESS_GRANTED',
        'OPEN_BLE_ACCESS_DENIED',
    ];

    const callback = () => {
        loadEntranceHistory(null,filter)
    }
    const debounce = useDebounce(callback,2)

    useEffect(() => {
        loadEntranceHistory();
    }, [loadEntranceHistory, location]);

    return (<>
            <Row>
                <Col xs={6}>
                    <h1>{t('entrances.entranceHistoryPage')}</h1>
                </Col>
            </Row>
            <FilterInput
                filter={filter}
                setValue={(e) => {
                    setFilter((prev) => ({ ...prev, value: e.target.value }))
                    debounce()
                }
                }
                setKey={(e) =>
                    setFilter((prev) => ({ ...prev, key: e.target.value }))
                }
                filterList={[
                    { key: 'lockName', text: t('entrances.lockName') },
                    { key: 'phoneNumber', text: t('entrances.granteeNumber') },
                    { key: 'lockId', text: t('entrances.lockId') },
                ]}
            />
            <Row>
                <Col>
                {
                isLoading ? (
                    <div className="d-flex justify-content-center mt-5">
                        <Loader size={50} />
                    </div>
                ) : (
                <InfiniteScroll
                    dataLength={entranceHistory.length}
                    next={() => loadEntranceHistory(lastEntrance,filter)}
                    hasMore={lastEntrance != null}
                    loader={<div className="d-flex justify-content-center mt-5">
                        <Loader size={50} />
                    </div>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
                        <Table responsive="xl" bordered striped>
                            <thead>
                                <tr>
                                    <th>{t('entrances.lockName')}</th>
                                    <th>{t('entrances.facilityName')}</th>
                                    <th>{t('entrances.entranceTime')}</th>
                                    <th>{t('entrances.action')}</th>
                                    <th>{t('entrances.accessType')}</th>
                                    <th>{t('entrances.accessName')}</th>
                                    <th>{t('entrances.granteeNumber')}</th>
                                    <th>{t('entrances.grantorNumber')}</th>
                                    <th>{t('entrances.timeLabel')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entranceHistory.map((item) => {
                                    if (knownAccess.includes(item.type))
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.lockName}</td>
                                                <td>{item.facility}</td>
                                                <td>{item.entranceTime}</td>
                                                <td>
                                                    {item.tokenOrKey === 'key'
                                                        ? t(
                                                            `key.${item.actionType}`
                                                        )
                                                        : t(
                                                            `token.${item.actionType}`
                                                        )}
                                                </td>
                                                <td>{item.tokenOrKey}</td>
                                                <td>{item.customName}</td>
                                                <td>{item.phoneNumber}</td>
                                                <td>{item.grantor}</td>
                                                <td>{`${item.dateFrom} - ${
                                                    item.dateTo !== undefined
                                                        ? item.dateTo
                                                        : ''
                                                }`}</td>
                                            </tr>
                                        );
                                    else
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.lockName}</td>
                                                <td>{item.facility}</td>
                                                <td>{item.entranceTime}</td>
                                                <td>
                                                    {t(
                                                        `entranceTypes.${item.type}`
                                                    )}
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        );
                                })}
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                )}
                </Col>
            </Row>
        </>
    );
};

EntranceHistoryPage.protoTypes = {
    loadEntranceHistory: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lastEntrance: PropTypes.string,
    entranceHistory: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    entranceHistory: state.entranceHistory.entranceHistory,
    lastEntrance: state.entranceHistory.lastEntrance,
    isLoading: state.entranceHistory.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    loadEntranceHistory: (lastEntrance,filter) => dispatch(loadEntranceHistory(lastEntrance,filter)),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(EntranceHistoryPage);
