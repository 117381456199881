import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { login } from '../../store/actions/authActions';
import PropTypes from 'prop-types';
import classes from './Login.module.scss';
import { Row, Col, Form, Button } from 'react-bootstrap';
import logo from '../../assets/images/logo.png';
import { withTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const Login = ({ t, login, isAuthenticated }) => {
    const [loginError, setLoginError] = useState(null);
    const {
        isSubmitting,
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setSubmitting,
        values,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false,
        },
        onSubmit: async (values) => {
            try {
                await login(values.email, values.password, values.rememberMe);
            } catch (error) {
                if (error.message === 'wrong user')
                    setLoginError(t('error.UserError'));
                else setLoginError(t('error.UnknownError'));
            }

            setSubmitting(false);
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(t('auth.validEmailError'))
                .required(t('auth.enterEmail')),
            password: Yup.string().required(t('auth.enterPassword')),
        }),
    });

    if (isAuthenticated && !isSubmitting) {
        return <Redirect to="/" />;
    }

    return (
        <Row
            className={`justify-content-center align-items-center ${classes.wrapper}`}
        >
            <Col xs="auto" className={classes.loginForm}>
                <img
                    src={logo}
                    alt="Rentinglock logo"
                    className={classes.logo}
                />
                <p className={classes.welcomeText}>
                    {t('auth.welcomeLoginText')}
                </p>
                <Form noValidate onSubmit={handleSubmit} className="w-100 mt-3">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>{t('auth.email')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('auth.enterEmail')}
                            {...getFieldProps('email')}
                        />
                        <Form.Text className="form-error">
                            {touched['email'] && errors['email']}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>{t('auth.password')}</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder={t('auth.enterPassword')}
                            {...getFieldProps('password')}
                        />
                        <Form.Text className="form-error">
                            {touched['password'] && errors['password']}
                        </Form.Text>
                    </Form.Group>

                    <Form.Check
                        type="checkbox"
                        id={`check-api-checkbox`}
                        className="p-0"
                    >
                        <Form.Check.Label className={classes.rememberMe}>
                            <Form.Check.Input
                                className={`${classes.checkboxInput} ${
                                    values.rememberMe ? classes.checked : ''
                                }`}
                                type="checkbox"
                                {...getFieldProps('rememberMe')}
                            />
                            <span className={classes.checkmark}></span>
                            {t('auth.rememberMe')}
                        </Form.Check.Label>
                    </Form.Check>
                    <p className={`form-error ${classes.errorText}`}>
                        {loginError && loginError}
                    </p>
                    <Button
                        className="d-block mx-auto mb-4"
                        variant="primary"
                        type="submit"
                    >
                        {(isSubmitting && t('auth.progress')) ||
                            t('auth.login')}
                    </Button>
                </Form>
            </Col>
        </Row>
    );
};

Login.protoTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
    login: (name, password, rememberMe) =>
        dispatch(login(name, password, rememberMe)),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(Login);
