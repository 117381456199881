import { ACCESS_HISTORY } from '../actions/types';

const initialState = {
    accessHistory: [],
    isLoading: false,
    lastAccess: null,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case ACCESS_HISTORY.LOAD_ACCESS_HISTORY:
            return {
                ...state,
                accessHistory: payload.accessHistory,
                lastAccess: payload.lastAccess,
            };
        case ACCESS_HISTORY.UPDATE_ACCESS_HISTORY:
            return {
                ...state,
                accessHistory: [...state.accessHistory,...payload.accessHistory],
                lastAccess: payload.lastAccess,
            };
        case ACCESS_HISTORY.SET_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        default:
            return state;
    }
}
