import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AccessHistoryPage from '../pages/accessHistory/AccessHistory';
import EntranceHistoryPage from '../pages/entranceHistory/EntranceHistory';
import LocksPage from '../pages/locks/Locks';
import NewLockPage from '../pages/locks/newLock/NewLock';
import BridgeScansPage from '../pages/locks/bridgeScans/BridgeScans';
import UsersPage from '../pages/users/Users';
import UserPage from '../pages/users/user/User';
import ChangePasswordPage from '../pages/users/user/ChangePassword';
import ChangeRolePage from '../pages/users/user/ChangeRole';
import PanelUsersPage from '../pages/panelUsers/PanelUsers';
import PanelUserPage from '../pages/panelUsers/user/PanelUser';

const Routes = () => {
    return (
        <Switch>
            <Route path="/locks" component={LocksPage} exact />
            <Route path="/locks/new" component={NewLockPage} exact />
            <Route path="/locks/:lockId/bridge-scans" component={BridgeScansPage} exact />
            <Route path="/users" component={UsersPage} exact />
            <Route path="/users/:id/details" component={UserPage} exact />
            <Route
                path="/users/:id/reset-password"
                component={ChangePasswordPage}
                exact
            />
            <Route
                path="/users/:id/:lock/change-role"
                component={ChangeRolePage}
                exact
            />
            <Route path="/panel-users" component={PanelUsersPage} exact />
            <Route path="/panel-users/new" component={PanelUserPage} exact />
            <Route
                path="/panel-users/:id/details"
                component={PanelUserPage}
                exact
            />
            <Route path="/access-history" component={AccessHistoryPage} exact />
            <Route
                path="/entrance-history"
                component={EntranceHistoryPage}
                exact
            />
            <Redirect to="/locks" />
        </Switch>
    );
};

export default Routes;
