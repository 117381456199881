import React, { useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { loadPanelUsers } from '../../../store/actions/panelUsersActions';

const PanelUsersPage = ({ t, location, loadPanelUsers, panelUsers }) => {
    useEffect(() => {
        loadPanelUsers();
    }, [location, loadPanelUsers]);

    return (
        <>
            <Row>
                <Col xs={6}>
                    <h1>{t('panelUsers.panelUsersPage')}</h1>
                </Col>
                <Col xs={6}>
                    <Link
                        to="/panel-users/new"
                        className="d-block mr-0 ml-auto btn btn-primary w-fit"
                    >
                        {t('panelUsers.add')}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive="xl" bordered striped>
                        <thead>
                            <tr>
                                <th>{t('panelUsers.name')}</th>
                                <th>{t('panelUsers.email')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {panelUsers &&
                                panelUsers.map((item) => (
                                    <tr key={item.id}>
                                        <td>{`${item.firstName} ${item.lastName}`}</td>
                                        <td>{item.email}</td>
                                        <td>
                                            <Link
                                                to={`/panel-users/${item.id}/details`}
                                                className="d-block mx-auto w-fit"
                                            >
                                                {t('panelUsers.edit')}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

PanelUsersPage.protoTypes = {
    loadPanelUsers: PropTypes.func.isRequired,
    panelUsers: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    panelUsers: state.panelUsers.users,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanelUsers: () => dispatch(loadPanelUsers()),
});

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(PanelUsersPage);
