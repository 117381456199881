import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as api from '../../../../store/utils/api/panelUsersApi';
import { Link, useHistory } from 'react-router-dom';

const PanelUserPage = ({ t, location }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    useEffect(() => {
        const userID = location.pathname.split('/')[2];
        if (userID !== null) {
            api.apiGetPanelUser(userID)
                .then((resp) => {
                    if (resp?.data) {
                        setUser((prev) => ({ ...prev, ...resp.data.user }));
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [location, setUser]);

    useEffect(() => {
        if (isDone) {
            history.push('/panel-users');
        }
    }, [isDone, history]);

    const {
        isSubmitting,
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setSubmitting,
    } = useFormik({
        enableReinitialize: true,
        initialValues: user,
        onSubmit: async (values) => {
            try {
                if (values.id) await api.apiPutPanelUser(values);
                else await api.apiPostPanelUser(values);
                setIsDone(true);
            } catch (error) {
                setError(t(`error.${error.data}`));
            }
            setSubmitting(false);
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required(t('panelUsers.enterFirstName')),
            lastName: Yup.string().required(t('panelUsers.enterLastName')),
            email: Yup.string()
                .email(t('auth.validEmailError'))
                .required(t('panelUsers.enterEmail')),
            password: user.id
                ? Yup.string()
                : Yup.string().required(t('panelUsers.enterPassword')),
        }),
    });

    const deleteUser = async () => {
        setIsDeleting(true);
        try {
            await api.apiDeletePanelUser(user.id);
            setIsDone(true);
        } catch (error) {
            setError(t(`error.${error.data}`));
        }
        setIsDeleting(false);
    };

    return (
        <>
            <Row>
                <Col xs={6}>
                    <h1>
                        {user.id
                            ? t('panelUsers.updatePanelUser')
                            : t('panelUsers.addPanelUser')}
                    </h1>
                </Col>
                <Col xs={6}>
                    <Link
                        to="/panel-users"
                        className="d-block mr-0 ml-auto btn btn-primary w-fit"
                    >
                        {t('panelUsers.back')}
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center mt-5">
                <Col xs={12} lg={10} xl={8}>
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between">
                            <Form.Group
                                controlId="formBasicFirstName"
                                className="w-50 pr-2"
                            >
                                <Form.Label>
                                    {t('panelUsers.firstName')}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('panelUsers.enterFirstName')}
                                    {...getFieldProps('firstName')}
                                />
                                <Form.Text className="form-error">
                                    {touched['firstName'] &&
                                        errors['firstName']}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group
                                controlId="formBasicLastName"
                                className="w-50 pl-2"
                            >
                                <Form.Label>
                                    {t('panelUsers.lastName')}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('panelUsers.enterLastName')}
                                    {...getFieldProps('lastName')}
                                />

                                <Form.Text className="form-error">
                                    {touched['lastName'] && errors['lastName']}
                                </Form.Text>
                            </Form.Group>
                        </div>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('panelUsers.email')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('panelUsers.enterEmail')}
                                {...getFieldProps('email')}
                            />
                            <Form.Text className="form-error">
                                {touched['email'] && errors['email']}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>{t('panelUsers.password')}</Form.Label>
                            <Form.Control
                                type="password"
                                autoComplete="off"
                                placeholder={t('panelUsers.enterPassword')}
                                {...getFieldProps('password')}
                            />
                            <Form.Text className="form-error">
                                {touched['password'] && errors['password']}
                            </Form.Text>
                        </Form.Group>
                        <p className="form-error">{error && error}</p>
                        {user.id ? (
                            <div className="d-flex justify-content-between">
                                <Button variant="danger" onClick={deleteUser}>
                                    {((isSubmitting || isDeleting) &&
                                        t('panelUsers.progress')) ||
                                        t('panelUsers.delete')}
                                </Button>
                                <Button variant="success" type="submit">
                                    {((isSubmitting || isDeleting) &&
                                        t('panelUsers.progress')) ||
                                        t('panelUsers.update')}
                                </Button>
                            </div>
                        ) : (
                            <Button variant="primary" type="submit">
                                {(isSubmitting && t('panelUsers.progress')) ||
                                    t('panelUsers.add')}
                            </Button>
                        )}
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default withTranslation()(PanelUserPage);
