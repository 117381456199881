import { LOCKS } from "../actions/types";

const initialState = {
  locks: [],
  isLoading: false,
  isLoadingBridge: false,
  offset: 0,
  scans: [],
  lastEntry: null,
  bestBridge: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case LOCKS.UPDATE_LOCKS:
      return {
        ...state,
        locks: [...state.locks,...payload.locks],
        offset: payload.offset,
      };
    case LOCKS.LOAD_LOCKS:
      return {
        ...state,
        locks: payload.locks,
        offset: payload.offset,
      };
    case LOCKS.UPDATE_LOCKS_SCANS:
      return {
        ...state,
        scans: [...state.scans,...payload.scans],
        lastEntry: payload.lastEntry,
      };
      case LOCKS.LOAD_LOCKS_SCANS:
        return {
          ...state,
          scans: payload.scans,
          bestBridge: payload.bestBridge,
        lastEntry: payload.lastEntry,
      };
    case LOCKS.SET_LOADING:
      return {
          ...state,
          isLoading: payload,
      };
    case LOCKS.SET_LOADING_SCANS:
      return {
          ...state,
          isLoadingBridge: payload,
      };
    default:
      return state;
  }
}
